import { Component, OnInit } from '@angular/core';
import { ITEMS, Item, ResidentItems, Race, IApplication } from './apply.model';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { formatDate } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { share } from 'rxjs/operators'
import { Router } from '@angular/router';
import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css'],
  providers: [NotificationsService]
})
export class ApplyComponent implements OnInit {

  isLinear = true;
  private IsEssayUploaded: boolean = false;
  private IsEssayUploading: boolean = false;
  private IsTranscriptUploaded: boolean = false;
  private IsTranscriptUploading: boolean = false;
  private IsTaxUploaded: boolean = false;
  private IsTaxUploading: boolean = false;
  private IsAsian: boolean = false;

  personalFormGroup: FormGroup;
  financialFormGroup: FormGroup;
  attachmentFormGroup: FormGroup;
  confirmFormGroup: FormGroup;
  startDate = new Date().getFullYear() - 21;
  minDate = new Date(new Date().getFullYear() - 99, new Date().getMonth(), new Date().getDate())
  maxDate = new Date(new Date().getFullYear() - 9, new Date().getMonth(), new Date().getDate())
  date = new FormControl(new Date(this.startDate, new Date().getMonth(), new Date().getDate()));
  ConfirmationDate = new FormControl({ value: new Date(), disabled: true });
  public radioitemsList: Item[] = ITEMS;
  public IsLoading: boolean = false;
  public IsResident: boolean = false;
  public IsOtherRace: boolean = false;
  //public radioResidentItems: Item[] = ResidentItems;
  public radioResidentItems: string[] = ['US Citizen', 'Permanent Resident', 'Citizens of the Freely Associated States'];
  public RaceItems: string[] = ['White', 'Black/African American/African', 'American Indian and / or Alaskan Native', 'Native Hawaiian or Other Pacific Islander', 'Asian'];
  public AsianRaceItems: string[] = ['Korean', 'Chinese', 'Japanese', 'Asian Indian', 'Vietnamese', 'Filipino', 'Guamanian or Chamorro', 'Samoan', 'Other Asian', 'Other race']
  public genderItems: string[] = ['Male', 'Female', 'Transgender Male', 'Transgender Female', 'Other', 'Prefer not to answer'];
  public radioStateItems: string[] = ['California', 'Georgia', 'Illinois', 'New York', 'Washington', 'New Jersey', 'Texas', 'Virginia', 'Alabama'];
  public EthnicityItems: string[] = ['Hispanic or Latino', 'Not Hispanic or Latino']
  public Attachments: File[] = [];
  public EssayFile: string = '';
  public TranscriptFile: string = '';
  public TaxFile: string = '';
  public EssayFile64: string = '';
  public TranscriptFile64: string = '';
  public TaxFile64: string = '';
  public fileNames: string[] = [];
  public showFiles: boolean = false;
  public RaceValue: string = '';
  public AsianRaceValue: string = '';
  public UniqueID: string = ''
  public Files: string[] = [];
  private Application: IApplication = {
    State: "", FirstName: "", LastName: "", MiddleName: "", DOB: "", Address: "", City: "", ResidentState: "", ZipCode: "", PhoneNumber: "", Email: "", GPA: "", Status: "", Race: "", Gender: "",
    Income: "", Ethnicity: "", ConfirmationName: "", Initials: "", ConfirmedDate: "", UniqueId: "", Files: []
  };

  public result: any;
  public NotificationOptions: any = {
    position: ['top', 'right'],
    timeOut: 2000
  };

  constructor(private notificationService: NotificationsService, private _formBuilder: FormBuilder, private $http: HttpClient, private http: HttpClient, private dialog: MatDialog, private router: Router) {

  }
  ngOnInit() {


    this.radioitemsList = ITEMS;

    this.personalFormGroup = this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      GPA: new FormControl('', Validators.required),
      residencyStatus: new FormControl('', Validators.required),
      ethnicity: new FormControl('', Validators.required),
      RaceItems: this._formBuilder.array([]),
      AsianRaceItems: new FormControl(''),
      otherRace: new FormControl(''),
      gender: new FormControl('', Validators.required),
      ResidentState: new FormControl('', Validators.required)
    });
    this.financialFormGroup = this._formBuilder.group({
      income: new FormControl('', Validators.required),
      tax: new FormControl('', Validators.required),
      taxfile: ''
    });
    this.attachmentFormGroup = this._formBuilder.group({
      essay: new FormControl('', Validators.required),
      transcript: new FormControl('', Validators.required),
      essayfile: '',
      transcriptfile: ''
    });
    this.confirmFormGroup = this._formBuilder.group({
      confirmationName: ['', Validators.required],
      initials: ['', Validators.required],
    });
  }


  onRaceChange(event) {
    const races = <FormArray>this.personalFormGroup.get('RaceItems') as FormArray;
    if (event.target.checked) {
      if (event.target.value == "Asian") {
        this.IsAsian = true;
        if (this.personalFormGroup.value.AsianRaceItems == "Other race" || this.personalFormGroup.value.AsianRaceItems == "Other Asian") {
          this.IsOtherRace = true;
        }
      }
      else {
        races.push(new FormControl(event.target.value))
      }
    } else {
      if (event.target.value == "Asian") {
        this.IsAsian = false;
        this.IsOtherRace = false;
      }
      else {
        const i = races.controls.findIndex(x => x.value === event.target.value);
        races.removeAt(i);
      }
    }
    this.RaceValue = '';
    for (let i = 0; i < races.length; i++) {
      this.RaceValue = this.RaceValue + races.value[i];
    }
  }

  onAsianRaceChange(event) {
    if (this.personalFormGroup.value.AsianRaceItems == "Other race" || this.personalFormGroup.value.AsianRaceItems == "Other Asian") {
      this.IsOtherRace = true;
    }
    else {
      this.IsOtherRace = false;
      //this.personalFormGroup.value.otherRace = "";
    }
    //const Asianraces = <FormArray>this.personalFormGroup.get('AsianRaceItems') as FormArray;
    //if (event.target.checked) {
    //  Asianraces.push(new FormControl(event.target.value))
    //} else {
    //  const i = Asianraces.controls.findIndex(x => x.value === event.target.value);
    //  Asianraces.removeAt(i);

    //}
    //if (Asianraces.length > 0) {
    //  this.AsianRaceValue = 'Asian,' + Asianraces.value[0];
    //}

  }

  onStateChange(event) {
    if (event.source.value) {
      this.IsResident = true;
    }
  }

  validateAttachments() {
    if (this.EssayFile && this.TranscriptFile) {
      return true;
    }
    return null;
  }
  //onPhoneNumberChange() {
  //  let enteredPhone = this.personalFormGroup.value.phone;
  //  let digits = ("" + enteredPhone).split("");
  //  if (digits.length <= 3) {
  //    this.personalFormGroup.value.phone = "+1 (" + enteredPhone + ")";
  //  }
  //}
  handleFileInput(files: FileList) {
    this.Attachments.push(files.item(0));
    this.fileNames.push(files.item(0).name);
    this.showFiles = true;
  }

  remove(file: any) {
    var index = this.Attachments.indexOf(file);
    this.Attachments.splice(index, 1);
    this.fileNames.splice(index, 1);
    if (this.fileNames.length == 0) {
      this.showFiles = false;
    }
  }


  async UploadFile(file: File, type: string) {

    let tempFile = file[0];
    let fileNameArray = tempFile.name.split('.');
    let fileExtension = '.' + fileNameArray[fileNameArray.length - 1];
    if (fileExtension != ".pdf") {
      this.notificationService.warn("Please upload only PDF files");
    }
    else {
      if (type == "Essay") {
        this.IsEssayUploading = true;
      }
      else if (type == "Tax") {
        this.IsTaxUploading = true;
      }
      else {
        this.IsTranscriptUploading = true;
      }
      if (!this.UniqueID) {
        this.UniqueID = this.personalFormGroup.value.lastName + formatDate(new Date(), 'yyyyMMddHHmmss', 'en-US');
      }

      const file2Base64 = await this.file2Base64(tempFile).then(value => { return value; });

      this.notificationService.success("Uploaded Successfully");

      if (type == "Essay") {
        this.EssayFile = tempFile.name;
        this.EssayFile64 = file2Base64;
        this.IsEssayUploading = false;
        this.IsEssayUploaded = true;
        this.attachmentFormGroup.setValue({
          "essay": this.EssayFile,
          "transcript": this.TranscriptFile,
          "essayfile": this.EssayFile64,
          "transcriptfile": this.TranscriptFile64
        });
      }
      else if (type == "Tax") {
        this.TaxFile = tempFile.name;
        this.TaxFile64 = file2Base64;
        this.IsTaxUploading = false;
        this.IsTaxUploaded = true;
        this.financialFormGroup.setValue({
          "income": this.financialFormGroup.value.income,
          "tax": this.TaxFile,
          "taxfile": this.TaxFile64
        });
      }
      else {
        this.TranscriptFile = tempFile.name;
        this.TranscriptFile64 = file2Base64;
        this.IsTranscriptUploading = false;
        this.IsTranscriptUploaded = true;
        this.attachmentFormGroup.setValue({
          "essay": this.EssayFile,
          "transcript": this.TranscriptFile,
          "essayfile": this.EssayFile64,
          "transcriptfile": this.TranscriptFile64
        });
      }      
    }
  }

  DeleteFile(type: string) {
    if (type == "Essay") {
      this.IsEssayUploading = true;
    }
    else if (type == "Tax") {
      this.IsTaxUploading = true;
    }
    else {
      this.IsTranscriptUploading = true;
    }

    this.notificationService.success("File has been deleted");

    if (type == "Essay") {
      this.EssayFile = "";
      this.EssayFile64 = "",
        this.IsEssayUploading = false;
      this.IsEssayUploaded = false;
      this.attachmentFormGroup.setValue({
        "essay": this.EssayFile,
        "transcript": this.TranscriptFile,
        "essayfile": this.EssayFile64,
        "transcriptfile": this.TranscriptFile64
      });
    }
    else if (type == "Tax") {
      this.TaxFile = "";
      this.TaxFile64 = "";
      this.IsTaxUploading = false;
      this.IsTaxUploaded = false;
      this.financialFormGroup.setValue({
        "income": this.financialFormGroup.value.income,
        "tax": this.TaxFile,
        "taxfile": this.TaxFile64
      });
    }
    else {
      this.TranscriptFile = "";
      this.TranscriptFile64 = "";
      this.IsTranscriptUploading = false;
      this.IsTranscriptUploaded = false;
      this.attachmentFormGroup.setValue({
        "essay": this.EssayFile,
        "transcript": this.TranscriptFile,
        "essayfile": this.EssayFile64,
        "transcriptfile": this.TranscriptFile64
      });
    }    
  }

  file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '').replace(/^.*;base64,/, "");
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    })
  }

  async apply() {
    if (this.EssayFile != "" && this.TranscriptFile != "") {
      if (this.confirmFormGroup.valid) {
        this.Application.Address = this.personalFormGroup.value.address;
        this.Application.City = this.personalFormGroup.value.city;
        this.Application.DOB = formatDate(this.date.value, 'yyyy-MM-dd', 'en-US');
        this.Application.Email = this.personalFormGroup.value.email;
        this.Application.FirstName = this.personalFormGroup.value.firstName;
        this.Application.LastName = this.personalFormGroup.value.lastName;
        this.Application.MiddleName = this.personalFormGroup.value.middleName;
        this.Application.State = this.personalFormGroup.value.state;
        this.Application.ZipCode = this.personalFormGroup.value.zip;
        this.Application.PhoneNumber = this.personalFormGroup.value.phone;
        this.Application.GPA = this.personalFormGroup.value.GPA;
        this.Application.Status = this.personalFormGroup.value.residencyStatus;
        this.Application.Gender = this.personalFormGroup.value.gender;
        this.Application.ResidentState = this.personalFormGroup.value.ResidentState;
        this.Application.Race = this.getRace();
        this.Application.Ethnicity = this.personalFormGroup.value.ethnicity;
        this.Application.Income = this.financialFormGroup.value.income;

        this.Application.ConfirmationName = this.confirmFormGroup.value.confirmationName;
        this.Application.Initials = this.confirmFormGroup.value.initials;
        this.Application.ConfirmedDate = this.ConfirmationDate.value;
        this.Application.UniqueId = this.UniqueID;
        this.IsLoading = true;

        this.Application.Files[0] = this.financialFormGroup.value.taxfile;
        this.Application.Files[1] = this.attachmentFormGroup.value.essayfile;
        this.Application.Files[2] = this.attachmentFormGroup.value.transcriptfile;

        await this.http.post("api/scholarship/apply", this.Application, { headers: { 'Content-Type': 'application/json' } }).pipe(share())
          .subscribe(result => {
            this.router.navigateByUrl('/confirm');
          });
      }
    }
    else {
      this.notificationService.warn("Please attach both Essay and Transcript");
    }
  }

  getRace() {
    if (this.RaceValue == "") {
      if (this.personalFormGroup.value.AsianRaceItems == "Other race" || this.personalFormGroup.value.AsianRaceItems == "Other Asian") {
        return "Asian, " + this.personalFormGroup.value.otherRace;
      }
      else {
        return "Asian, " + this.personalFormGroup.value.AsianRaceItems;
      }
    }
    else {
      return this.RaceValue;
    }
  }


}
