import { Component, OnInit, Inject  } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit{
  isExpanded = false;
  private URL: string[];
  private Koreanbanner: boolean;
  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {

  }
  ngOnInit() {
    this.URL = location.href.split('=');
    if (this.URL.length == 2) {
      this.Koreanbanner = true;
    }
    else {
      this.Koreanbanner = false;
    }
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
