export interface Item {
    text: string,
    value: string
}

export const ITEMS: Item[] = [
  { text: 'California', value: 'CA' },
  { text: 'New York', value: 'NY' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Washington', value: 'WA' },
  { text: 'Texas', value: 'TX' },
  { text: 'Alabama', value: 'AL' }
];

export const ResidentItems: Item[] = [
    { text: 'US Citizen', value: 'Citizen' },
    { text: 'Permanent Resident', value: 'PR' },
    { text: 'Citizens of the Freely Associated States', value: 'CitizenFSA' }
]

export const Race: Item[] = [
  { text: 'White', value: 'White' },
  { text: 'Black/African American/African', value: 'Black' },
  { text: 'Hispanic/Latino', value: 'Latino' },
  { text: 'American Indian and/or Alaskan Native', value: 'AI' },
  { text: 'Korean', value: 'Korean' },
  { text: 'Chinese', value: 'Chinese' },
  { text: 'Japanese', value: 'Japanese' },
]

export interface IApplication {
  State: string;
  FirstName: string;
  LastName: string;
  MiddleName: string; 
  DOB: string;
  Address: string; 
  City: string;
  ResidentState: string;
  ZipCode: string;
  PhoneNumber: string;
  Email: string;
  GPA: string;
  Status: string;
  Ethnicity: string;
  Race: string;
  Gender: string;
  Income: string;
  ConfirmationName: string;
  Initials: string;
  ConfirmedDate: string;
  UniqueId: string;
  Files: Array<string>
}
