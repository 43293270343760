import { Component, OnInit, Injectable } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  constructor(private locationStrategy: LocationStrategy) {
  }


  ngOnInit() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null,null,location.href)
    })
  }

}
